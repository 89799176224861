import type {Dispatch} from 'react-redux';
import {bindActionCreators as bindActions} from 'redux';

import type {RootState} from 'config/redux/rootReducer';
import type {SearchShowingsMarkets_searchShowingsMarkets_data} from 'components/pages/Showings/hooks/__generated__/SearchShowingsMarkets';

interface ExternalProps {
  value: string[];
  open: boolean;
  anchorEl: HTMLElement;
  hasChanges: boolean;
  resetTempValue: () => void;
  onChange: (markets: string[], ev?: React.SyntheticEvent) => void;
  onPopperClose: (temp?: string[]) => void;
  marketList: SearchShowingsMarkets_searchShowingsMarkets_data[];
}

const getMarkets = (state: RootState, externalProps: ExternalProps) =>
  Boolean(externalProps.marketList)
    ? externalProps.marketList.map((m) => ({
        id: m.name,
        code: m.code,
        name: m.name,
        active: true,
      }))
    : state.app.allMarkets;

const getOptions = (state: RootState) =>
  state.app.markets
    .map((m) => ({text: `${m.name} - ${m.code}`, value: m.code}))
    .sort((a, b) => a.text.localeCompare(b.text));

export const mapStateToProps = (state: RootState, externalProps: ExternalProps) => ({
  includeInactiveMarkets: state.app.includeInactiveMarkets,
  options: getOptions(state),
  markets: getMarkets(state, externalProps),
  ...externalProps,
});

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  ...bindActions({}, dispatch),
});

export type MapStateToProps = ReturnType<typeof mapStateToProps>;
export type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
