import type {History} from 'history';
import {all, put} from 'redux-saga/effects';

import {querySync, stringTransforms} from 'config/redux/modules/prism/lib';

import {actions} from '../actions';
import {acceptableImageQuerySync} from './acceptableImage';
import {locationQuerySync} from './location';
import {mediaQuerySync} from './media';

import type {Filters, InitialState} from '../reducers';
export enum UrlParams {
  limit = '$limit',
  skip = '$skip',
  sort = '$sort',
  searchTerm = 'searchTerm',
  panelStatus = 'panelStatus',
  market = 'market',
  curationStatus = 'curationStatus',
  media = 'media',
  location = 'location',
  ids = 'ids',
  format = 'format',
  dataOrigin = 'dataOrigin',
  prime = 'prime',
  acceptableImage = 'acceptableImage',
}

export default function* init(history: History<unknown>) {
  yield all([
    put(actions.resetPagination()),
    querySync(UrlParams.limit, {
      history,
      selector: ({panels: {pagination}}) => pagination.limit,
      action: actions.setPageLimit,
      stringToValue: parseInt,
    }),
    querySync(UrlParams.skip, {
      history,
      selector: ({panels: {pagination}}) => pagination.skip,
      action: actions.setPageSkip,
      stringToValue: parseInt,
      listeners: [actions.setFilters, actions.resetFilters],
    }),
    querySync(UrlParams.sort, {
      history,
      selector: ({panels: {pagination}}) => pagination.sort,
      action: actions.setPageSort,
      stringToValue: (s) => {
        let key = s;
        let value = 'asc';

        if (s.startsWith('-')) {
          key = s.replace('-', '');
          value = 'desc';
        }
        return {
          [key]: value,
        } as InitialState['pagination']['sort'];
      },
      valueToString: (v) => {
        const key = Object.keys(v)[0];
        return `${v[key] === 'desc' ? '-' : ''}${key}`;
      },
    }),
    querySync(UrlParams.searchTerm, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        searchTerm: stringTransforms.stringToValue(s),
      }),
      valueToString: (v) => stringTransforms.valueToString(v.searchTerm),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync(UrlParams.panelStatus, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        panelStatus: split(s) as Filters['panelStatus'],
      }),
      valueToString: (v) => join(v.panelStatus),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync(UrlParams.market, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        market: split(s) as Filters['market'],
      }),
      valueToString: (v) => join(v.market),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync(UrlParams.curationStatus, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        curationStatus: split(s) as Filters['curationStatus'],
      }),
      valueToString: (v) => join(v.curationStatus),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {curationStatus: null as Filters['curationStatus']},
    }),
    mediaQuerySync(history),
    locationQuerySync(history),
    querySync(UrlParams.ids, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        inventory: split(s) as Filters['inventory'],
      }),
      valueToString: (v) => join(v.inventory),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync(UrlParams.format, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        format: s as Filters['format'],
      }),
      valueToString: (v) => v.format,
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync(UrlParams.dataOrigin, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        dataOrigin: split(s) as Filters['dataOrigin'],
      }),
      valueToString: (v) => join(v.dataOrigin),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    querySync(UrlParams.prime, {
      history,
      selector: ({panels: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        prime: s === 'true' ? true : null,
      }),
      valueToString: (v) => (v.prime ? 'true' : ''),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    }),
    acceptableImageQuerySync(history),
  ]);
}

export const join = (arr: string[], separator: string = '|') =>
  arr ? arr.map((a) => stringTransforms.valueToString(a)).join(separator) : '';

export const split = (text: string, separator: string = '|') =>
  (text || '')
    .split(separator)
    .filter((t) => !!t)
    .map((t) => stringTransforms.stringToValue(t));
